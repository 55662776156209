import { HeadFC } from "gatsby";
import React from "react";
import Breadcrumbs from "../components/breadcrumbs";
import SEO from "../components/seo";

export interface DoctorPageProps {
  title: string;
  subline: string | React.ReactNode;
  children: React.ReactNode;
  portrait: React.ReactNode;
}

const DoctorPage: React.FC<DoctorPageProps> = ({
  title,
  subline,
  children,
  portrait,
}) => {
  return (
    <div className="w-full">
      <div className="relative w-full bg-beige">
        <div className="pt-6 md:h-112 container-7xl">
          <Breadcrumbs />
          <div className="grid grid-cols-12">
            <div className="flex flex-col h-full col-span-12 py-6 md:col-span-8 md:justify-center">
              <h1 className="text-[40px] leading-tight">{title}</h1>
              {typeof subline === "string" ? <p>{subline}</p> : subline}
            </div>
            <div className="col-span-12 md:col-span-4 h-96">
              <div className="absolute bottom-0 right-0">{portrait}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 py-6 mx-auto container-7xl">
        <h2 className="text-2xl">Anerkennungen und Zusatzqualifikationen</h2>
        <div className="grid grid-cols-12 gap-6">
          {React.Children.map(children, (child, index) => child)}
        </div>
      </div>
    </div>
  );
};

export const Head: HeadFC = () => <SEO />;

export default DoctorPage;
