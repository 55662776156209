import React from "react";

export interface InfoBoxProps {
  title: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ title }) => {
  return (
    <div className="flex items-center justify-center col-span-12 sm:col-span-6 md:col-span-4 px-8 py-4 bg-beige rounded-xl min-h-[80px]">
      <span className="text-center">{title}</span>
    </div>
  );
};

export default InfoBox;
