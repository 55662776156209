/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import React from "react";
import {getImage, GatsbyImage} from 'gatsby-plugin-image';
import DoctorPageTemplate from "../../templates/doctor-page";
import InfoBox from "../../components/info-box";
import PortraitSoljan from "../../components/portraits/portrait-soljan";
const MDXLayout = ({children}) => React.createElement(DoctorPageTemplate, {
  title: "Dr. phil. Dipl. Psych. Andreas Šoljan",
  subline: "Psychologischer Psychotherapeut",
  portrait: React.createElement(PortraitSoljan)
}, children);
function _createMdxContent(props) {
  return React.createElement(React.Fragment, null, React.createElement(InfoBox, {
    title: "Psychoanalyse"
  }), "\n", React.createElement(InfoBox, {
    title: "Tiefenpsychologisch fundierte Psychotherapie"
  }), "\n", React.createElement(InfoBox, {
    title: "Verhaltenstherapie"
  }), "\n", React.createElement(InfoBox, {
    title: "Einzelbehandlung und Gruppen"
  }), "\n", React.createElement(InfoBox, {
    title: "Supervisor und Lehrtherapeut Verhaltenstherapie, tiefenpsychologisch fundierte Psychotherapie und Psychoanalyse"
  }), "\n", React.createElement(InfoBox, {
    title: "Psychoonkologie"
  }));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
